import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/userStore";

export default {
  name: "Login",
  setup() {
    const userStore = useUserStore();
    const userName = ref("");
    const password = ref("");
    const show1 = ref(false);

    onMounted(() => {
      const user = userStore.getUser;
      if (user.userName) {
        window.location.href = "/";
      }
    });
    async function login() {
      try {
        await userStore.login(userName.value, password.value);
      } catch (e) {
        console.error(e);
      }
    }
    return { userName, password, show1, login };
  },
};
