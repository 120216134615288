import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Login from "@/views/Login/Login.vue";
import SheetsList from "@/views/SheetsList/SheetsList.vue";
import Settings from "@/views/Settings/Settings.vue";
import ChordsList from "@/views/ChordsList/ChordsList.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/sheetsList",
    name: "sheetsList",
    component: SheetsList,
  },

  {
    path: "/sheetsList/:id",
    name: "sheetsListWithId",
    component: SheetsList,
    props: true,
  },
  {
    path: "/chords",
    name: "chords",
    component: ChordsList,
  },
  {
    path: "/chords/:id",
    name: "chordsWithId",
    component: ChordsList,
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  routes,
});

export default router;
