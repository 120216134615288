import { useUserStore } from '@/stores/userStore'
import { useSheetStore } from '@/stores/sheetStore'
import { useChordsStore } from '@/stores/chordsStore'
import { onMounted, computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { useTheme } from 'vuetify'

export default {
  name: 'Dashboard',
  setup() {
    const userStore = useUserStore()
    const chordStore = useChordsStore()
    const theme = useTheme()
    const sheetStore = useSheetStore()
    const userName = computed(() => {
      return `${userStore.user.firstName} ${userStore.user.lastName}`
    })
    const numFrets = ref(5)
    const numStrings = ref(6)
    const user = computed(() => {
      return userStore.user
    })
    const loggedIn = computed(() => {
      return !!user.value._id
    })

    const lastUpdatedSheets = computed(() => {
      return sheetStore.lastUpdatedSheets
    })
    const lastUpdatedChords = computed(() => {
      return chordStore.lastUpdatedChords
    })
    const darkTheme = computed(() => {
      return theme.global.current.value.dark
    })

    onMounted(async () => {
      userStore.checkLogin()
      await sheetStore.getLastUpdatedSheets()
      await chordStore.getLastUpdatedChords()
    })

    function parseDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT)
    }

    return {
      lastUpdatedSheets,
      darkTheme,
      loggedIn,
      lastUpdatedChords,
      userName,
      numFrets,
      numStrings,
      parseDate
    }
  }
}
