<template>
  <v-row>
    <v-col cols="12">
      <v-row class="d-flex justify-center justify-md-start">
        <v-col cols="6" md="3" class="pa-4">
          <v-btn class="w-100 color-white" color="primary" @click="createSheet()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-4">
          <SheetTable :sheets="sheets" @createSheet="createSheet" @editItem="editItem"></SheetTable>
        </v-col>
      </v-row>
    </v-col>
    <SheetEditDialog
      :editedItem="editedItem"
      :dialog="dialog"
      :allChords="allChords"
      @cancelEditing="cancelEditing"
      @saveSheet="saveSheet"
    ></SheetEditDialog>
  </v-row>
</template>
<script src="./SheetsList.js"></script>
