import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useAlertStore } from "@/stores/alertStore";

export const useChordsStore = defineStore("chords", () => {
  const chords = ref([]);
  const alertStore = useAlertStore();
  const userStore = useUserStore();
  const lastUpdatedChords = ref([]);

  async function fetchChords() {
    try {
      const headers = userStore.getTokenHeader;
      const response = await axios.get(`${import.meta.env.VITE_SERVICE_API_URL}chords`, {
        headers,
      });
      chords.value = response.data;
    } catch (e) {
      console.error(e);
      await userStore.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }

  async function createChord(chord) {
    try {
      const headers = userStore.getTokenHeader;
      await axios.post(`${import.meta.env.VITE_SERVICE_API_URL}chords`, chord, {
        headers,
      });
      await this.fetchChords();
    } catch (e) {
      console.error(e);
      await userStore.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }
  async function updateChord(chord) {
    try {
      const headers = userStore.getTokenHeader;
      await axios.put(`${import.meta.env.VITE_SERVICE_API_URL}chords/${chord.value._id}`, chord.value, {
        headers,
      });
      await this.fetchChords();
    } catch (e) {
      console.error(e);
      await userStore.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }

  async function deleteChord(id) {
    try {
      const headers = userStore.getTokenHeader;
      await axios.delete(`${import.meta.env.VITE_SERVICE_API_URL}chords/${id}`, {
        headers,
      });
      await this.fetchChords();
    } catch (e) {
      console.error(e);
      await userStore.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }
  async function getLastUpdatedChords() {
    try {
      const headers = userStore.getTokenHeader;
      const response = await axios.get(
        `${import.meta.env.VITE_SERVICE_API_URL}chords/getLastUpdated`,
        {
          headers,
        },
      );
      lastUpdatedChords.value = response?.data ?? [];
    } catch (e) {
      console.error(e);
      await userStore.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }
  return {
    chords,
    lastUpdatedChords,
    fetchChords,
    deleteChord,
    updateChord,
    createChord,
    getLastUpdatedChords,
  };
});
