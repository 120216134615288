import { DateTime } from "luxon";
import { useSheetStore } from "@/stores/sheetStore.js";

export default {
  name: "SheetTable",
  props: ["sheets"],
  setup(props) {
    const sheetStore = useSheetStore();
    const headers = [
      { title: "Song Titel", key: "songTitle" },
      { title: "Songwriter", key: "songWriter" },
      { title: "Zuletzt geändert", key: "updatedAt" },
      { title: "Aktionen", key: "actions", sortable: false },
    ];

    function deleteItem(id) {
      try {
        const confirm = window.confirm("Möchtest du das Item wirklich löschen?");
        if (confirm) sheetStore.deleteSongSheet(id);
      } catch (e) {
        console.error(e);
      }
    }

    function parseDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
    }
    return { props: props.sheets, headers, parseDate, deleteItem };
  },
};
