<template>
  <v-layout>
    <v-app-bar v-if="loggedIn">
      <v-app-bar-title>{{ getPageName() }}</v-app-bar-title>
      <v-btn icon="mdi-home" href="/" color="secondary"></v-btn>
      <v-btn icon="mdi-bell-badge" color="secondary"></v-btn>
      <v-btn
        icon="mdi-theme-light-dark"
        @click="toggleTheme()"
        class="mr-3"
        color="secondary"
      ></v-btn>
      <v-avatar class="mr-5" size="25px"><v-img cover :src="avatar"></v-img></v-avatar>
    </v-app-bar>
    <v-navigation-drawer
      v-if="loggedIn"
      :expand-on-hover="true"
      :rail="true"
      :disable-resize-watcher="true"
      class="menu__list"
    >
      <v-list>
        <v-list-item
          :prepend-avatar="avatar"
          :title="`${user.firstName} ${user.lastName}`"
          :subtitle="user.eMail"
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item
          prepend-icon="mdi-home"
          title="Startseite"
          value="home"
          href="/"
          :active="isCurrentPath('home')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-file-music"
          title="Songs"
          value="mySongs"
          href="sheetsList"
          :active="isCurrentPath('sheetsList')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-music"
          title="Chords"
          value="chords"
          href="chords"
          :active="isCurrentPath('chords')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-toolbox"
          title="Einstellungen"
          value="settings"
          href="/settings"
          :active="isCurrentPath('settings')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-logout"
          title="Abmelden"
          value="logout"
          @click="logout"
          href="/login"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view style="height: 100%" />
      <ErrorAlert></ErrorAlert>
    </v-main>
  </v-layout>
</template>
<script src="./App.js"></script>
<style>
.menu__list {
  .v-list-item {
    color: rgb(var(--v-theme-secondary));
  }
}
.v-navigation-drawer {
  border-radius: 0 15px 15px 0;
  margin-top: 5px;
}
.v-toolbar {
  border-radius: 0 0 10px 10px;
}
.color-main-primary {
  color: rgb(var(--v-theme-primary));
}
.cursor-pointer {
  cursor: pointer;
}
.color-black {
  color: black;
}
</style>
