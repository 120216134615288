<template>
  <div class="text-center">
    <v-snackbar
      v-if="isError"
      v-model="activeAlert"
      :timeout="timeout"
      content-class="error"
      multi-line
    >
      {{ alertText }}

      <template v-slot:actions>
        <v-btn color="white" icon="mdi-close" @click="deactivateAlert()"> </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-else :timeout="timeout" v-model="activeAlert" content-class="success" multi-line>
      {{ alertText }}

      <template v-slot:actions>
        <v-btn color="white" icon="mdi-close" @click="deactivateAlert()"> </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script src="./ErrorAlert.js"></script>
<style>
.error {
  background-color: #b00020;
}
.success {
  background-color: #4caf50;
}
</style>
