<template>
  <v-row>
    <v-col cols="12">
      <v-row class="d-flex justify-center justify-md-start">
        <v-col cols="6" md="3" class="pa-4">
          <v-btn color="primary" class="w-100" @click="createChord()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-4"
          ><ChordsTable :chords="chords" @editItem="editItem"></ChordsTable
        ></v-col>
      </v-row>
    </v-col>
    <ChordEditDialog
      :dialog="dialog"
      :editedItem="editedItem"
      @closeDialog="closeDialog()"
    ></ChordEditDialog>
  </v-row>
</template>
<script src="./ChordsList.js"></script>
