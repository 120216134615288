<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="true"
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>"{{ song.songTitle }}" bearbeiten</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn variant="text" @click="$emit('cancelEditing')"> Abbrechen </v-btn>
          <v-btn variant="text" @click="$emit('saveSheet', false)"> Speichern </v-btn>
          <v-btn variant="text" @click="$emit('saveSheet', true)"> Speichern & Schließen </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row class="pa-2">
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                color="primary"
                label="Songtitel"
                v-model="song.songTitle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                color="primary"
                label="Songwriter"
                v-model="song.songWriter"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                color="primary"
                type="text"
                label="Key"
                v-model="song.songKey"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                color="primary"
                type="number"
                label="BPM"
                min="0"
                v-model="song.songSpeed"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                color="primary"
                type="number"
                label="Capo (Bund)"
                min="0"
                v-model="song.capo"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                color="primary"
                label="Schwierigkeit"
                :items="difficultyItems"
                v-model="song.difficulty"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-switch color="primary" label="Tab-Editor" v-model="tabEditor"></v-switch>
            </v-col>
            <v-col cols="12" md="3" v-if="!tabEditor">
              <v-switch color="primary" label="Vorschau" v-model="preview"></v-switch>
            </v-col>
            <v-col cols="12" md="3" v-if="tabEditor">
              <v-btn color="primary" class="w-100" @click="addTabTemplate"
                >Tab-Template Einfügen</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                :items="allChords"
                v-model="song.chords"
                item-title="name"
                item-value="_id"
                label="Akkorde"
                no-data-text="Keine Akkorde gefunden"
                chips
                multiple
                color="primary"
                closable-chips
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="bg-white ma-md-2"
              v-for="(chord, index) in editedItem.chords"
              :key="index"
            >
              <guitar-chord
                :name="getChordData(chord).name"
                nut-position="0"
                :tuning="getChordData(chord).tuning"
                :fretted="getChordData(chord).chord"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="pa-4" v-if="!tabEditor">
        <v-col cols="12" md="6" class="pa-0 pr-2 color-black">
          <ckeditor :editor="editor" v-model="song.songText"></ckeditor>
        </v-col>
        <v-col
          v-if="preview"
          cols="12"
          md="6"
          style="border: 1px solid #2f4858"
          class="pdf mt-7 mt-md-0"
        >
          <div v-html="previewSongText"></div>
        </v-col>
      </v-row>
      <v-row v-if="tabEditor" class="pa-4">
        <v-col cols="12" md="6" class="pa-0 pr-2 color-black tab_editor">
          <ckeditor :editor="editor" v-model="song.tab"></ckeditor>
        </v-col>
      </v-row>
      <v-row class="pa-3 mt-3">
        <v-col cols="12">
          <v-divider class="mb-3"></v-divider>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center justify-md-end">
          <v-btn color="primary" class="mr-3" @click="downloadPdf">Download PDF</v-btn>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
          <v-btn color="primary" @click="uploadPdf">PDF in Drive hochladen</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script src="./SheetEditDialog.js"></script>
<style>
.ck-content {
  color: black;
}

.pdf {
  color: black !important;
  background-color: white;
}

.songTitle {
  font-size: 20px;
  color: black;
}

p:has(> .comment) {
  margin-bottom: 12px;
}

.comment {
  display: flex;
  justify-content: center;
  background-color: #bdbdbd;
  padding: 6px;
}

.chordLine {
  max-height: 25px;
}

.textLine {
  margin-bottom: 10px;
}

.chord {
  color: black;
  font-weight: bold;
  vertical-align: text-bottom;
}
.tab_editor {
  font-size: 18px;
}
</style>
