<template>
  <v-data-table
    :items="chords"
    :headers="headers"
    no-data-text="Keine Akkorde gefunden"
    :items-per-page-options="[
      { value: 5, title: '5' },
      { value: 10, title: '10' },
      { value: 25, title: '25' },
      { value: 50, title: '50' },
      { value: 100, title: '100' },
      { value: -1, title: 'Alle' },
    ]"
    items-per-page-text="Einträge pro Seite"
    :pageText="'{0}-{1} von {2}'"
  >
    <template v-slot:item.name="{ item }">
      <span
        @click="$emit('editItem', item)"
        class="color-main-primary font-weight-bold cursor-pointer"
        >{{ item.name }}</span
      >
    </template>
    <template v-slot:item.tuning="{ item }">
      <span>{{ item.tuning }}</span>
    </template>
    <template v-slot:item.updatedAt="{ item }">
      <span>{{ parseDate(item.updatedAt) }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon size="small" class="me-2" color="primary" @click="$emit('editItem', item)">
        mdi-pencil
      </v-icon>
      <v-icon size="small" color="red" @click="deleteItem(item._id)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script src="./ChordsTable.js"></script>
