import { defineStore } from "pinia";
import { ref } from "vue";

export const useAlertStore = defineStore("alert", () => {
  const alert = ref("Es ist ein unerwarteter Fehler aufgetreten");
  const active = ref(false);
  const isError = ref(true);

  function createAlert(error = true, alertText = "") {
    alert.value = alertText.length
      ? alertText
      : error
      ? "Es ist ein unerwarteter Fehler aufgetreten"
      : "Erfolgreich";
    active.value = true;
    isError.value = error;
  }

  function deactivateAlert() {
    active.value = false;
  }

  return { alert, active, isError, createAlert, deactivateAlert };
});
