import ChordsTable from "@/components/ChordsTable/ChordsTable.vue";
import ChordEditDialog from "../../components/ChordEditDialog/ChordEditDialog.vue";
import { useChordsStore } from "@/stores/chordsStore";
import { computed, onMounted, ref } from "vue";
export default {
  name: "ChordsList",
  components: { ChordsTable, ChordEditDialog },
  props: ["id"],
  setup(props) {
    const chordsStore = useChordsStore();
    const defaultItem = ref({
      name: "G",
      chord: [{}],
      tuning: "EADGBe",
      nutPosition: 0,
    });
    const editedItem = ref({});
    const chordId = computed(() => {
      return props.id;
    });

    const chords = computed(() => {
      return chordsStore.chords;
    });
    const dialog = ref(false);

    onMounted(async () => {
      await chordsStore.fetchChords();

      checkForChordToEdit();
    });

    function checkForChordToEdit() {
      if (chordId.value) {
        const foundChord = chords.value?.find((chord) => chord._id === chordId.value);

        if (!foundChord?._id) return;
        editedItem.value = foundChord;
        dialog.value = true;
      }
    }

    function createChord() {
      editedItem.value = defaultItem.value;
      dialog.value = true;
    }

    function editItem(item) {
      editedItem.value = item;
      dialog.value = true;
    }

    function closeDialog() {
      const url = new URL(window.location.href);
      if (url.href.includes(editedItem.value._id)) window.location.href = "/chords";
      dialog.value = false;
      editedItem.value = {};
    }

    return { chords, dialog, editedItem, createChord, editItem, closeDialog };
  },
};
