import { ref, onMounted } from "vue";
import BaseChord from "../BaseChord.vue";
import Fretboard from "../Fretboard.vue";
import FrettedNote from "../FrettedNote.vue";
import String from "../String.vue";

let svgPoint;
let START_Y = 20;
let FRET_HEIGHT = 20 * 0.8;

export default {
  name: "GuitarChordEditor",
  components: {
    BaseChord,
    // eslint-disable-next-line vue/no-unused-components
    Fretboard,
    FrettedNote,
    String,
  },
  props: ["chord"],
  setup(props) {
    const chord = ref(props.chord?.chord);
    const frettedNotes = ref(Array.of([], [], [], [], [], []));
    const selected = ref(null);
    const name = ref("X Akkord");
    const tuning = ref("EADGBe");
    const nutPosition = ref(0);
    const svg = ref(null);
    function onKeyUp(e) {
      const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (e.key === "Backspace" || e.key === "Delete") remove();
      if (numbers.includes(e.key)) setFinger(event);
      /*
          @keyup.up="moveFret(-1)"
          @keyup.down="moveFret(1)"
          */
    }

    onMounted(() => {
      svg.value = svg.value.$el;
      svgPoint = svg.value.createSVGPoint();
      frettedNotes.value = [
        getValue(chord.value, 0),
        getValue(chord.value, 1),
        getValue(chord.value, 2),
        getValue(chord.value, 3),
        getValue(chord.value, 4),
        getValue(chord.value, 5),
      ];
      if (props.chord.tuning) tuning.value = props.chord.tuning;
      if (props.chord.name) name.value = props.chord.name;
      if (props.chord.nutPosition) nutPosition.value = props.chord.nutPosition;
    });

    function getValue(chords, index) {
      let returnVal = null;
      if (chords?.length) {
        chords.forEach((chord) => {
          if (chord?.string === index) returnVal = [chord];
          if (Array.isArray(chord?.string) && chord?.string.includes(index)) returnVal = [chord];
        });
      }
      if (returnVal !== null) return returnVal;
      else return [];
    }

    function noteClicked(note) {
      selected.value = note;
    }
    // When an empty string is clicked, create a note at that position.
    // If the string isn't empty, but sits below a barred note, also create
    // a note at that position.
    // Otherwise, there is a (non-barred) note above or below, and we should
    // simply move it to this new position.
    function stringClicked(clickedString, event) {
      let clickedFret = fretFromClick(event);
      let notes = frettedNotes.value[clickedString];
      let simpleNote = findSimpleNote(notes);
      let barredNote = findBarredNote(notes);
      if (!notes.length || (barredNote && barredNote.fret < clickedFret)) {
        let newNote = {
          string: clickedString,
          fret: clickedFret,
          finger: 1,
        };
        notes.splice(clickedFret, 0, newNote);
        selected.value = newNote;
      } else {
        simpleNote["fret"] = clickedFret;
        selected.value = simpleNote;
      }
    }

    function fretFromClick(event) {
      svgPoint.x = event.clientX;
      svgPoint.y = event.clientY;
      let inversePt = svg.value.getScreenCTM().inverse();
      let svgClick = svgPoint.matrixTransform(inversePt);
      return Math.floor(1 + (svgClick.y - START_Y) / FRET_HEIGHT);
    }

    function findSimpleNote(notes) {
      return notes.find((x) => !Array.isArray(x.string));
    }

    function findBarredNote(notes) {
      return notes.find((x) => Array.isArray(x.string));
    }

    function moveFret(change) {
      if (!selected.value) {
        return;
      }
      let newValue = selected.value.fret + change;
      if (newValue >= 1 && newValue <= 5) {
        selected.value["fret"] = newValue;
      }
    }

    function remove() {
      if (!selected.value) {
        return;
      }
      let string = Array.isArray(selected.value.string)
        ? selected.value.string[0]
        : selected.value.string;
      let notes = frettedNotes[string];
      let updatedNotes = notes.filter((x) => x !== selected.value);
      frettedNotes[string] = updatedNotes;
      selected.value = null;
    }

    function setFinger(event) {
      if (!selected.value) {
        return;
      }
      selected.value["finger"] = event.key;
    }
    return {
      chord,
      name,
      svg,
      tuning,
      nutPosition,
      selected,
      frettedNotes,
      remove,
      setFinger,
      moveFret,
      stringClicked,
      noteClicked,
      getValue,
      onKeyUp,
    };
  },
};
