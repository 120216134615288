import UserEditDialog from "@/components/UserEditDialog/UserEditDialog.vue";
import { useUserStore } from "@/stores/userStore";
import { onMounted, ref } from "vue";
export default {
  name: "Settings",
  components: { UserEditDialog },
  setup() {
    const userStore = useUserStore();
    const dialog = ref(false);

    onMounted(() => {
      userStore.checkLogin();
    });

    async function cancelEditing() {
      const confirm = window.confirm(
        "Möchten Sie wirklich abbrechen? Alle Änderungen gehen verloren",
      );
      if (confirm) dialog.value = false;
      await userStore.loadUser();
    }

    async function saveUser(close = false) {
      if (close) dialog.value = false;
      await userStore.updateUser();
    }
    return { dialog, cancelEditing, saveUser };
  },
};
