import { createApp } from "vue";
import { createPinia } from "pinia";
import { vuetify } from "@/plugins/vuetify.js";
import CKEditor from "@ckeditor/ckeditor5-vue";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);
app.use(vuetify);
app.use(CKEditor);
app.use(createPinia());
app.use(router);

Sentry.init({
  app,
  dsn: "https://1f7127918a8b7b263df3d2e5db9c73f2@o4507573509226496.ingest.de.sentry.io/4507573511585872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/jononnweiler\.de/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");
