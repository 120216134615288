<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>"{{ user.firstName }} {{ user.lastName }}" bearbeiten</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn variant="text" @click="$emit('cancelEditing')"> Abbrechen </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-btn variant="text" @click="saveUser()"> Speichern </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-btn variant="text" @click="saveUser(true)"> Speichern & Schließen </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              color="secondary"
              label="Benutzername"
              v-model="user.userName"
            ></v-text-field>
            <v-text-field color="secondary" label="Vorname" v-model="user.firstName"></v-text-field>
            <v-text-field color="secondary" label="Nachname" v-model="user.lastName"></v-text-field>

            <v-text-field
              color="secondary"
              label="E-Mail Adresse"
              v-model="user.eMail"
            ></v-text-field>
            <v-text-field color="secondary" label="Passwort" v-model="user.password"></v-text-field>
            <v-select
              color="secondary"
              label="Theme"
              v-model="newTheme"
              :items="themes"
              @update:modelValue="changeTheme"
            ></v-select>
            <v-file-input
              v-model="image"
              @change="upload()"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Foto hochladen"
              prepend-icon="mdi-camera"
              label="Avatar"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <v-img height="500" contain :src="avatar"></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script src="./UserEditDialog.js"></script>
