<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Akkord "{{ editedItem?.name }}" bearbeiten</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn variant="text" @click="deleteChanges()"> Abbrechen </v-btn>
            <v-btn variant="text" @click="saveChord(false)"> Speichern </v-btn>
            <v-btn variant="text" @click="saveChord(true)"> Speichern & schließen </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list>
          <v-list-item>
            <v-text-field label="Akkord Name" v-model="editedItem.name"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field label="Akkord Tuning" v-model="editedItem.tuning"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
              type="number"
              min="0"
              label="Start Bund"
              v-model="editedItem.nutPosition"
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-row>
              <v-col cols="12" md="3">
                <GuitarChordEditor
                  :chord="editedItem"
                  :chordName="editedItem.name"
                  :nutPos="editedItem.nutPosition"
                  @sendChordInformation="sendChordInformation"
                ></GuitarChordEditor>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script src="./ChordEditDialog.js"></script>
