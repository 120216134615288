import { computed, ref } from "vue";
import { useAlertStore } from "@/stores/alertStore";
export default {
  name: "ErrorAlert",
  setup() {
    const alertStore = useAlertStore();
    const alertText = computed(() => {
      return alertStore.alert;
    });
    const activeAlert = computed({
      get() {
        return alertStore.active;
      },
      set(value) {
        alertStore.active = value;
      },
    });

    const isError = computed(() => {
      return alertStore.isError;
    });
    const timeout = 2000;

    function deactivateAlert() {
      alertStore.deactivateAlert();
    }

    return { alertText, timeout, activeAlert, isError, deactivateAlert };
  },
};
