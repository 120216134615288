export const getTabTemplate = () => `
[Intro]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
[Verse 1]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Chorus]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Verse 2]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Chorus]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Bridge]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Chorus]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
[Outro]
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
<p>e|---------------------------------------------------------------------------|<br>
B|---------------------------------------------------------------------------|<br>
G|---------------------------------------------------------------------------|<br>
D|---------------------------------------------------------------------------|<br>
A|---------------------------------------------------------------------------|<br>
E|---------------------------------------------------------------------------|</p>
<p></p>
************************************
<p></p>
| x   Dead note<br>
| g   Grace note<br>
| (n) Ghost note<br>
| h   Hammer-on<br>
| p   Pull-off<br>
| r   Release<br>
| >   Accented note<br>
| t   Tapping<br>
| b   Bend<br>
| br  Bend release<br>
| pb  Pre-bend<br>
| pbr Pre-bend release<br>
| /   Slide up<br>
| \\   Slide down<br>
| ~   Vibrato<br>
| s   Slap<br>
| P   Pop<br>
| PM  Palm mute<br>
| TR  Trill<br>
| N   Tremolo
<p></p>
************************************`;
