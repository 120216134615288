import { DateTime } from "luxon";
import { useChordsStore } from "../../stores/chordsStore";

export default {
  name: "ChordsTable",
  props: ["chords"],
  setup() {
    const chordStore = useChordsStore();
    const headers = [
      {
        title: "Name",
        key: "name",
      },
      {
        title: "Stimmung",
        key: "tuning",
      },
      {
        title: "Zuletzt bearbeitet",
        key: "updatedAt",
      },
      { title: "Aktionen", key: "actions", sortable: false },
    ];

    function parseDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
    }

    async function deleteItem(id) {
      const confirm = window.confirm("Möchten Sie diesen Akkord wirklich löschen?");
      if (confirm) await chordStore.deleteChord(id);
    }

    return { headers, parseDate, deleteItem };
  },
};
