<template>
  <v-row class="d-flex justify-center" v-if="loggedIn">
    <v-col cols="10" class="mt-10">
      <v-row>
        <v-col cols="12">
          <h1>Guten Tag {{ userName }}!</h1>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card href="/sheetsList" class="color-white cards" color="primary">
            <v-card-title class="d-flex justify-center font-weight-bold"
              >Songs <v-icon class="ml-1">mdi-file-music</v-icon></v-card-title
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card href="/chords" class="color-white cards" color="primary">
            <v-card-title class="d-flex justify-center font-weight-bold"
              >Akkorde <v-icon class="ml-1">mdi-music</v-icon></v-card-title
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-list class="sheets__list pt-0 pb-0">
            <v-list-item
              v-for="(lastEditedSheet, index) in lastUpdatedSheets"
              :key="index"
              class="last_updated_item"
              :href="`/sheetsList/${lastEditedSheet._id}`"
            >
              <v-list-item-title>{{ lastEditedSheet.songTitle }}</v-list-item-title>
              <v-list-item-subtitle>{{
                parseDate(lastEditedSheet.updatedAt)
              }}</v-list-item-subtitle>
              <v-list-item-action>
                <v-icon :class="darkTheme ? 'color-white' : ''">mdi-open-in-new</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list class="chords__list pt-0 pb-0">
            <v-list-item
              v-for="(lastEditedChord, index) in lastUpdatedChords"
              :key="index"
              class="last_updated_item"
              :href="`/chords/${lastEditedChord._id}`"
            >
              <v-list-item-title>{{ lastEditedChord.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                parseDate(lastEditedChord.updatedAt)
              }}</v-list-item-subtitle>
              <v-list-item-action>
                <v-icon :class="darkTheme ? 'color-white' : ''">mdi-open-in-new</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script src="./Dashboard.js"></script>
<style>
.sheets__list {
  border-radius: 5px;
}

.chords__list {
  border-radius: 5px;
}

.cards {
  min-height: 350px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.last_updated_item {
  border-radius: 5px;
  padding: 15px;
}
.last_updated_item:hover {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: white;
}
</style>
