<template>
  <v-row class="pa-3">
    <v-col cols="12">
      <v-row class="mt-4">
        <v-col cols="12" md="4">
          <v-card class="d-flex card" @click="dialog = true" color="primary">
            <v-row>
              <v-col cols="3" md="2">
                <v-card-item>
                  <v-icon>mdi-account</v-icon>
                </v-card-item>
              </v-col>
              <v-col cols="9" md="4">
                <v-card-title>Account</v-card-title>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-card-text
                  >Hier können Sie alle Informationen zu Ihrem Account sehen &
                  bearbeiten</v-card-text
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <UserEditDialog
        :dialog="dialog"
        @cancelEditing="cancelEditing"
        @saveUser="saveUser"
      ></UserEditDialog>
    </v-col>
  </v-row>
</template>
<script src="./Settings.js"></script>
<style>
.card {
  border-radius: 25px !important;
}
</style>
