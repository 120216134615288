<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h1>Login</h1>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="10" sm="6">
          <v-row>
            <v-col cols="12">
              <v-text-field label="Benutzername" v-model="userName" color="primary" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                label="Passwort"
                color="primary"
                @keydown.enter="login"
                @click:append-inner="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn class="w-100" color="primary" @click="login">Anmelden</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script src="./Login.js"></script>
