import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { useAlertStore } from "@/stores/alertStore";
import axios from "axios";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: useStorage("user", {}),
    jwt: useStorage("jwt", ""),
  }),

  getters: {
    getUserAvatarUrl() {
      return `${import.meta.env.VITE_SERVICE_API_URL}user/avatar/${this.user._id}`;
    },
    getUser() {
      return this.user;
    },
    getTokenHeader() {
      return { Authorization: `Bearer ${this.jwt}` };
    },
  },

  actions: {
    checkLogin() {
      if (!this.user._id) window.location.href = "/login";
    },
    async login(userName, password) {
      const alertStore = useAlertStore();
      try {
        const response = await axios.post(`${import.meta.env.VITE_SERVICE_API_URL}auth/login`, {
          userName: userName,
          password: password,
        });
        if (response.status === 200) {
          this.user = response.data.user;
          this.jwt = response.data.access_token;
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
        alertStore.createAlert("Benutzername / Passwort falsch!");
      }
    },
    async loadUser() {
      const alertStore = useAlertStore();
      const headers = { Authorization: `Bearer ${this.jwt}` };
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_SERVICE_API_URL}user/${this.user._id}`,
          {
            headers,
          },
        );
        this.user = response.data;
        if (!this.user?._id) throw Error("Etwas ist schiefgelaufen");
      } catch (error) {
        console.error(error);
        await this.checkForJWTExpired(error);
        alertStore.createAlert();
      }
    },
    async logout() {
      const savedTheme = localStorage.getItem("theme");
      localStorage.clear();
      localStorage.setItem("theme", savedTheme);
      window.location.href = "/login";
    },

    async updateUser() {
      const alertStore = useAlertStore();
      const headers = { Authorization: `Bearer ${this.jwt}` };
      try {
        const response = await axios.put(
          `${import.meta.env.VITE_SERVICE_API_URL}user/${this.user._id}`,
          this.user,
          {
            headers,
          },
        );
        if (response.status === 200 || response.status === 204) {
          alertStore.createAlert(false, "Erfolgreich aktualisiert");
          const theme = response.data?.theme ?? "defaultTheme";
          localStorage.setItem("theme", theme);
          this.user = response.data;
        }
      } catch (error) {
        console.error(error);
        await this.checkForJWTExpired(error);
        alertStore.createAlert();
      }
    },

    async checkForJWTExpired(errorMessage) {
      if (errorMessage.response.data === "jwt expired") await this.logout();
    },

    async uploadUserAvatar(image) {
      const alertStore = useAlertStore();
      const headers = { Authorization: `Bearer ${this.jwt}` };
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_SERVICE_API_URL}user/avatar/${this.user._id}`,
          image,
          {
            headers,
          },
        );
        if (response.status === 200) {
          alertStore.createAlert(false, "Erfolgreich hochgeladen");
          this.user.avatar = response.data;
        }
      } catch (error) {
        console.error(error);
        await this.checkForJWTExpired(error);
        alertStore.createAlert(true, "Ein Fehler beim Avatar ist aufgetreten!");
      }
    },
  },
});
