import { useUserStore } from "@/stores/userStore";
import ErrorAlert from "@/components/ErrorAlert/ErrorAlert.vue";
import { computed, onMounted } from "vue";
import { useTheme } from "vuetify";

export default {
  name: "App",
  components: { ErrorAlert },
  setup() {
    const theme = useTheme();
    const userStore = useUserStore();
    const avatar = userStore.getUserAvatarUrl;
    const user = computed(() => {
      return userStore.user;
    });
    const loggedIn = computed(() => {
      return !!user.value._id;
    });

    onMounted(() => {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      const savedTheme = localStorage.getItem("theme");
      if (savedTheme?.length && savedTheme !== "null") theme.global.name.value = savedTheme;
      else if (darkThemeMq.matches) theme.global.name.value = "defaultTheme";
    });
    function getPageName() {
      const url = new URL(window.location.href)?.pathname;
      if (url === "/") return "Dashboard";
      if (url === "/sheetsList") return "Songs";
      if (url === "/chords") return "Akkorde";
      if (url === "/settings") return "Einstellungen";
    }
    async function logout() {
      await userStore.logout();
    }

    function toggleTheme() {
      theme.global.name.value = theme.global.current.value.dark ? "blueTheme" : "defaultTheme";
      localStorage.setItem("theme", theme.global.name.value);
    }

    function isCurrentPath(path) {
      const currentPath = new URL(window.location.href)?.pathname;
      if (currentPath && currentPath.includes(path)) return true;
      if (currentPath === "/" && path === "home") return true;
      return false;
    }

    return { user, loggedIn, avatar, logout, getPageName, toggleTheme, isCurrentPath };
  },
};
