import { defineStore } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useAlertStore } from "@/stores/alertStore";
import axios from "axios";
import { ref } from "vue";
import { jsPDF } from "jspdf";

export const useSheetStore = defineStore("sheets", () => {
  const sheets = ref([]);
  const alertStore = useAlertStore();
  const userStorage = useUserStore();
  const lastUpdatedSheets = ref([]);

  async function getSheets() {
    const headers = userStorage.getTokenHeader;
    try {
      const response = await axios.get(`${import.meta.env.VITE_SERVICE_API_URL}sheets/`, {
        headers,
      });
      if (response.data) {
        sheets.value = response.data;
      }
    } catch (err) {
      console.error(err);
      await userStorage.checkForJWTExpired(err);
      alertStore.createAlert();
    }
  }

  async function createSheet(songObject) {
    const headers = userStorage.getTokenHeader;
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_SERVICE_API_URL}sheets/`,
        songObject,
        {
          headers,
        },
      );
      if (response?.data?._id) {
        alertStore.createAlert(false, "Erfolgreich erstellt");
      }
    } catch (err) {
      console.error(err);
      await userStorage.checkForJWTExpired(err);
      alertStore.createAlert();
    }
  }

  async function updateSongSheet(songObject) {
    const headers = userStorage.getTokenHeader;
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_SERVICE_API_URL}sheets/${songObject._id}`,
        songObject,
        {
          headers,
        },
      );
      if (response?.status === 200) {
        console.info("Erfolgreich geupdated");
        alertStore.createAlert(false, "Erfolgreich aktualisiert");
      }
    } catch (error) {
      console.error(error);
      await userStorage.checkForJWTExpired(error);
      alertStore.createAlert();
    }
  }

  async function deleteSongSheet(id) {
    const headers = userStorage.getTokenHeader;
    try {
      const response = await axios.delete(`${import.meta.env.VITE_SERVICE_API_URL}sheets/${id}`, {
        headers,
      });
      if (response.status === 200) {
        console.info(response.data);
        alertStore.createAlert(false, "Erfolgreich gelöscht");
      }
      await getSheets();
    } catch (error) {
      console.error(error);
      await userStorage.checkForJWTExpired(error);
      alertStore.createAlert();
    }
  }

  async function createPdfForUpload(html, song) {
    const headers = userStorage.getTokenHeader;
    try {
      const doc = jsPDF();
      doc.html(html, {
        callback: async function (doc) {
          doc.setTextColor(255, 0, 0);
          const blob = doc.output("blob");
          const formData = new FormData();
          formData.append("pdf", blob, `${song.songTitle}.pdf`);
          await axios.post(
            `${import.meta.env.VITE_SERVICE_API_URL}sheets/uploadPdf/${song._id}`,
            formData,
            {
              headers,
            },
          );
        },
        x: 15,
        y: 15,
        width: 180, //target width in the PDF document
        windowWidth: 650, //window width in CSS pixels
      });
      alertStore.createAlert(false, "Erfolgreich hochgeladen");
    } catch (e) {
      console.error(e);
      await userStorage.checkForJWTExpired(e);
      alertStore.createAlert();
    }
  }

  async function getLastUpdatedSheets() {
    try {
      const headers = userStorage.getTokenHeader;
      const response = await axios.get(
        `${import.meta.env.VITE_SERVICE_API_URL}sheets/getLastUpdated`,
        {
          headers,
        },
      );
      lastUpdatedSheets.value = response?.data ?? [];
    } catch (e) {
      console.error(e);
      alertStore.createAlert();
    }
  }
  return {
    sheets,
    lastUpdatedSheets,
    getSheets,
    updateSongSheet,
    deleteSongSheet,
    createSheet,
    createPdfForUpload,
    getLastUpdatedSheets,
  };
});
